import * as React from 'react';
import API from "../../../common/AxoisClient";
import {Select2Wrapper} from "../../../common/Select2";
import {getQueryStringParams,SearchSelect, SearchInput} from "../../../common";
const moment = require('moment');

interface IState {
  form: {
    like: {
      message: string,
    },
    equal: {
      action_by: number
    }
  }
 }

export default class SMSFilter extends React.Component<any, IState> {
  state: IState;


  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
          message: old_data ? old_data.like.message : '',
        },
        equal: {
          action_by: old_data ? old_data.equal.action_by : ''
        }
      }})
  }

  handleInputForm = (e: React.ChangeEvent<any>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess},() =>  this.props.getResults(this.state.form));
  }

  translatorList = async (params: any) => {
    const translator = await API.post('activitylogs/user-search-list', {params: params});
       return translator.data;
  }

  render(){
    const Insta = this;
    return (
      <div className="row align-items-center">
      <SearchInput name={'form.like.message'} value={this.state.form.like.message} onChange={this.handleInputForm} placeholder={'Enter message'} />
      <div className="col-md-4 kt-margin-b-20-tablet-and-mobile">
    <div className="kt-form__group kt-form__group--inline">
        <div className="kt-form__label">
         
        <label/>
        </div>
        <div className="kt-form__control">
      <Select2Wrapper name={'form.equal.action_by'} value={this.state.form.equal.action_by} onChange={this.handleInputForm} className={'form-control'}
              
                 data= {{
                   
                    ajax: {
                        
                        transport: function (params,success, failure) {
                            let request = Insta.translatorList(params.data);
                            request.then(success);
                            request.catch(failure);
                            return request;
                          }
                      },
                      placeholder: 'Select User',
                       minimumInputLength: 3,
                      
                  }}
                           
        />
         </div>
        </div>
        </div>
    </div>
    )
  }
}