
declare var window: any;

export const LanguageValidations = function () {

  $(document).ready(function () {

    $( "#admin_user_form" ).validate({
      // define validation rules
      rules: {
        country_id: {
          required: true
        },
		language_name: {
          required: true
        }
      }
      
    });
  });
}