import * as React from 'react';
import {DateInput, getQueryStringParams,SearchSelect, SearchInput} from "../../../common";

const moment = require('moment');

interface IState {
  form: {
    like: {
      data: string,
    }
    
  }
 }

export default class NotificationFilter extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
        like: {
          data: old_data ? old_data.like.data : '',
          
        } 
      }})
  }

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess},() =>  this.props.getResults(this.state.form));
  }

   

  render(){
    
    return (
      <div className="row align-items-center">
      <SearchInput name={'form.like.data'} value={this.state.form.like.data} onChange={this.handleInputForm} placeholder={'Search for...'} />
     </div>
    )
  }
}