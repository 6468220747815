import React, {useEffect, useReducer, useState} from 'react';
import {Modal} from "react-bootstrap";
import {
  InitialState, PopupState, ActionType,
  CAction, OrderPopupReducer
} from "./OrderPopupReducer";
import { SeeNewletterService} from "../../../services";
import _ from 'lodash';


export function SeeEmail () {

  const [state, dispatch] = useReducer<React.Reducer<PopupState, CAction>>(OrderPopupReducer , InitialState);

  useEffect( () => {
    SeeNewletterService.getPopupState().subscribe((message:any) => {
      dispatch({type: ActionType.SHOW_POPUP, payload: message}) ;
    });

  }, []);

  if(!state.show){
    return null;
  }

   
  function createMarkup() {
    return {__html: state.orders.content};
  }
 
  return(
    
    <Modal
    
      show={state.show}
      onHide={() => { dispatch({type: ActionType.HIDE_POPUP}) } }
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <form  id="fix_price_form">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {!_.isEmpty(state.orders.users) &&  <React.Fragment> {state.orders.users.name}  ({state.orders.users.email})</React.Fragment> }
          {_.isEmpty(state.orders.users) &&  state.orders.custom_email}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </Modal.Body>
        <Modal.Footer>
        <button type={'button'} className={'btn btn-primary'} onClick={() => { dispatch({type: ActionType.HIDE_POPUP}) }}>Close</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}