import * as React from 'react';
import { getQueryStringParams,SearchSelect, SearchInput} from "../../../common";
const moment = require('moment');

interface IState {
  form: {
    like: {
        name: string,
        type: string,
    },
    equal: {
        status: number
    }
},
 }

export default class CompanyFilter extends React.Component<any, IState> {
  state: IState;

  UNSAFE_componentWillMount() {
    let old_data: any = getQueryStringParams("form");
    old_data = JSON.parse(old_data);
    this.setState({form:  {
            like: {
                name: old_data ? old_data.like.name : '',
                type: old_data ? old_data.like.type : ''
            },
            equal: {
                status: old_data ? old_data.equal.status : ''
            }
        }})
}

  handleInputForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name.split(".");
    let newAccess: any = Object.assign({}, this.state.form);
    newAccess[name[1]][name[2]] = e.target.value;
    this.setState({form: newAccess},() =>  this.props.getResults(this.state.form));
  }

  

  render(){
   return (
      <div className="row align-items-center">
 
 <SearchInput name={'form.like.name'} value={this.state.form.like.name} placeholder={'Enter name'} onChange={this.handleInputForm}  />
  <SearchSelect name={'form.equal.status'} value={this.state.form.equal.status} onChange={this.handleInputForm} options={[{'label': "Select Status", "value": ""}, {'label': "Active", "value": 1}, {'label': "Inactive", "value": 2}]}/> </div>
    )
  }
}